<template>
  <div class="card">
    <!-- Accordion Header -->
    <div class="card-header p-0" v-bind:id="componentId + '-head'">
      <h2 class="mb-0">
        <button
          class="btn btn-white btn-block text-left p-3 collapsed"
          type="button"
          data-toggle="collapse"
          v-bind:data-target="'#' + componentId + '-collapse'"
          aria-expanded="true"
          v-bind:aria-controls="componentId + '-collapse'"
        >
          <h5 class="custom-card-title">
            <i v-if="icon" v-bind:class="icon"></i>
            <span>{{ getTitle }}</span>
            <i
              class="fas fa-chevron-down float-right font-14 mt-1 text-muted"
            ></i>
          </h5>
        </button>
      </h2>
    </div>
    <!-- Accordion Header -->

    <!-- Product Accordion Body -->
    <div
      v-bind:id="componentId + '-collapse'"
      class="collapse"
      v-bind:class="{ show: !collapsed }"
      v-bind:aria-labelledby="componentId + '-head'"
    >
      <div class="card-body">
        <div class="row" v-if="searchableBrand">
          <div class="col">
            <div class="position-relative" v-click-outside="onClose">
              <!-- Search Product -->
              <input
                type="text"
                v-bind:id="componentId + '-head'"
                class="form-control"
                v-bind:placeholder="searchTitle"
                v-model="searchTerm"
                v-on:input="onInput"
                v-on:focus="selectDropVisible = true"
                v-on:blur="onBlur"
                ref="searchInput"
              />
              <!-- ./Search Product -->

              <!-- Result Count -->
              <div
                class="result-count"
                v-if="searchTerm && searchTerm.length >= length"
              >
                <div v-if="!ready">
                  <i class="fas fa-spinner fa-spin"></i>
                </div>
                <div v-else>
                  <div
                    v-if="
                      searchTerm.length &&
                      filteredBrandList &&
                      filteredBrandList.length
                    "
                  >
                    <span class="badge badge-pill alert-primary"
                      >{{ filteredBrandList.length }} {{ $t("result") }}</span
                    >
                  </div>
                  <div v-else>
                    <span class="badge badge-pill alert-dark">{{
                      $t("thereWereNoResults")
                    }}</span>
                  </div>
                </div>
              </div>
              <!-- ./Result Count -->

              <!-- Product Result Card -->
              <div
                class="
                  dropdown-menu
                  shadow-sm
                  col-12
                  custom-scrollbar
                  result-card
                "
                v-bind:class="{
                  show: filteredBrandList.length && selectDropVisible,
                }"
              >
                <span
                  class="btn dropdown-item py-2"
                  v-for="brand in filteredBrandList"
                  v-bind:key="brand.id"
                  v-on:click="selectBrand(brand)"
                >
                  <div class="row align-items-center">
                    <div class="col-auto">
                      <input
                        type="checkbox"
                        v-bind:checked="
                          computedData.findIndex((cd) => cd.id === brand.id) !=
                          -1
                        "
                        v-if="multipleSelect"
                      />
                      <template>
                        {{ brand.name }}
                      </template>
                    </div>
                  </div>
                </span>
              </div>
              <!-- Product Result Card -->
            </div>
          </div>
          <div class="col-auto" v-if="create">
            <button class="btn btn-info" v-on:click="onCreate">
              <i class="fas fa-plus fa-sm"></i> {{ $t("add") }}
            </button>
          </div>
        </div>
        <!-- Product List -->

        <div
          class="custom-scrollbar mt-3"
          v-bind:class="{ 'accordion-overflow': bodyOverflow }"
          v-if="computedData && computedData.length"
        >
          <WeTable
            v-bind:index="false"
            v-bind:data="computedData"
            v-bind:columns="columns"
            v-bind:actions="actions"
            v-bind:searchable="searchable"
            v-bind:searchText="searchText"
            v-bind:limitable="limitable"
            v-bind:pageDetails="false"
            v-bind:paginatable="computedData.length > itemsPerPage"
            v-bind:itemsPerPage="itemsPerPage"
            v-bind:selectable="false"
            v-bind:row-class="'font-14'"
            v-on:on-action="onAction"
            v-bind:responsive="bodyOverflow"
          >
          </WeTable>
        </div>
        <!-- ./Product List -->
      </div>
    </div>

    <!-- ./Product Accordion Body -->
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "WeBrand",
  props: {
    columns: {
      type: Array,
      default: () => [{ name: "name", th: "Marka Adı", type: "string" }],
    },
    brandsData: {
      type: Array,
      default: () => [],
    },
    productId: {
      default: null,
    },
    multipleSelect: {
      default: false,
    },
    actions: {
      type: Array,
      default: () => [
        {
          icon: "fas fa-trash fa-sm",
          class: "btn-outline-danger border-0 ml-2",
          action: "remove",
        },
      ],
    },
    resultHeight: {
      default: "auto",
    },
    showTotal: {
      default: false,
    },
    searchText: {
      default: "Çapraz Ürün Ara",
    },
    type_id: {
      default: 0,
    },
    quantity: {
      type: Boolean,
      default: false,
    },
    searchTitle: {
      type: String,
      default: "Marka Ara",
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    searchableBrand: {
      type: Boolean,
      default: true,
    },
    limitable: {
      type: Boolean,
      default: false,
    },
    paginatable: {
      type: Boolean,
      default: false,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    create: {
      type: Boolean,
      default: false,
    },
    name: {
      default: "",
    },
    title: {
      default: "",
    },
    icon: {
      default: "",
    },
    length: {
      default: 3,
    },
    delay: {
      default: 1000,
    },
    brands: {
      default: () => [],
    },
    removeSelf: {
      default: true,
    },
    collapsed: {
      default: false,
    },
    showVat: {
      default: false,
    },
    bodyOverflow: {
      default: true,
    },
    campaignDiscount: {
      default: 0,
    },
  },
  data() {
    return {
      ready: false,
      searchTerm: "",
      filteredBrandList: [],
      selectDropVisible: false,
      totalPrice: 0,
      subTotal: 0,
    };
  },
  methods: {
    ...mapActions("shared", ["search"]),
    onInput(e) {
      this.doSearch(e.target.value, this.delay);
    },
    doSearch(text, timeout) {
      this.filteredBrandList = [];

      let localTimeout = 0;
      if (timeout) {
        localTimeout = timeout;
      }

      if (text && text.trim().length) {
        if (text.trim().length >= this.length) {
          this.emitSearch(text, localTimeout);
        }
      }
    },
    emitSearch(text, timeout) {
      clearTimeout(this.timer);
      this.onBlur();
      this.ready = false;
      this.timer = setTimeout(() => {
        clearTimeout(this.timer);
        this.search({
          route: "search/brands",
          form: { name: text, type_id: this.type_id },
          onSuccess: (result) => {
            var items = result.data.items.sort();
            helper.copy(items, this.filteredBrandList);
            this.ready = true;
            this.selectDropVisible = true;
            this.$refs.searchInput.focus();
          },
        });
      }, timeout);
    },
    onBlur() {
      if (this.multipleSelect) return;
      setTimeout(() => {
        this.selectDropVisible = false;
      }, 250);
    },
    onClose() {
      this.selectDropVisible = false;
    },
    onSelect() {
      this.$emit("on-select", this.computedData);
    },
    unSelect(id) {
      this.$emit("un-select", id);
    },
    selectBrand(brand, event = null) {
      const index = this.computedData.findIndex((cd) => cd.id === brand.id);
      if (index == -1) {
        this.computedData.push(brand);
        this.onSelect();
      } else if (this.name !== "cross") {
        if (this.multipleSelect) {
          this.computedData.splice(index, 1);
        } else {
          this.computedData[index].quantity++;
          this.computedData.sort();
        }
        this.unSelect(brand.id);
      }
      if (!this.multipleSelect) this.clearList();
      else {
        this.filteredBrandList.sort();
      }
    },
    clearList() {
      this.searchTerm = "";
      this.ready = false;
      this.filteredBrandList = [];
    },
    onCreate() {
      this.$emit("on-create");
    },
    onAction(data) {
      if (data.key == "remove") {
        let message = "Silme işlemini onaylıyor musunuz ?";
        this.$swal({
          title: message,
          showCancelButton: true,
          cancelButtonText: "İptal",
          confirmButtonText: "Sil",
          icon: "warning",
        }).then((confirm) => {
          if (confirm.isConfirmed) {
            this.onDelete(data);
          }
        });
      }

      if (data.key == "edit") {
        this.$emit("on-edit", data);
      }
    },
    onDelete(data) {
      this.$emit("on-remove", data);
      if (this.removeSelf) {
        this.unSelect(this.computedData[data.index]["id"]);
        this.computedData.splice(data.index, 1);
      }
    },
    onQtyChange(data) {
      if (this.quantity) {
        let index = helper.arrayFind(this.computedData, "id", data.row.id);
        this.computedData[index].quantity = data.value;
      }
    },
  },
  model: {
    prop: "brands",
  },
  computed: {
    ...mapState({
      config: (state) => state.session.config,
      searchState: (state) => state.shared.search,
    }),
    getTitle() {
      return this.title.toLocaleUpperCase();
    },
    componentId() {
      return this.name + "-" + helper.getRandomInteger(1, 999999);
    },
    computedData: {
      get() {
        return this.brands.filter((A) => A.types[0].id == this.type_id);
      },
      set(value) {
        this.data.brands = value;
      },
    },
    vatType() {
      return this.config["site.vat_type"] || "excluded";
    },
    vatAmount() {
      return this.config["site.vat"] || 18;
    },
  },
};
</script>
<style lang="scss" scoped>
.accordion-overflow {
  overflow-x: hidden;
}
.result-count {
  position: absolute;
  right: 10px;
  z-index: 1060;
  top: 7px;
}
.result-card {
  max-height: 250px;
  overflow: auto;
}
.compitable span {
  padding: 10px;
}
.close-icon {
  cursor: pointer;
  font-weight: 600;
}
</style>
